import React from 'react';
import { classes } from './Header.st.css';
import { Filters } from '../../../../shared/components/Filters/Filters';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';
import { WeeklyDatepicker } from '../../../../shared/components/WeeklyDatepicker';
import { Title } from '../../../../shared/components/Title';
import { Timezone } from '../../../../shared/components/Timezone';
import { HeaderDataHooks } from './const';

export const Header: React.FC = () => {
  const { titleViewModel, filtersViewModel, timeZoneViewModel } =
    useWidgetViewModel();

  return (
    <div className={classes.root} data-hook={HeaderDataHooks.Main}>
      <Title titleViewModel={titleViewModel} />
      <div
        className={classes.filtersContainer}
        data-hook={HeaderDataHooks.FiltersContainer}
      >
        <div
          className={classes.weeklyDatePicker}
          data-hook={HeaderDataHooks.WeeklyDatepickerContainer}
        >
          <WeeklyDatepicker />
        </div>

        <div
          className={classes.filters}
          data-hook={HeaderDataHooks.FiltersWrapper}
        >
          <Filters filtersViewModel={filtersViewModel} />
          <Timezone timeZoneViewModel={timeZoneViewModel} />
        </div>
      </div>
    </div>
  );
};
