import React from 'react';
import { FloatingDropdown, Text } from 'wix-ui-tpa/cssVars';
import { classes } from './Timezone.st.css';
import { TimeZoneViewModel } from '../../../components/DailyAgenda/viewModel/timeZoneViewModel/timeZoneViewModel';
import { useDailyAgendaActions } from '../../../components/DailyAgenda/hooks/useDailyAgendaActions';
import { TimezoneDataHooks } from './const';

export interface TimezoneProps {
  timeZoneViewModel?: TimeZoneViewModel;
}

export const Timezone: React.FC<TimezoneProps> = ({ timeZoneViewModel }) => {
  const { onTimeZoneChange } = useDailyAgendaActions();
  const { label = '', options = [] } = timeZoneViewModel || {};

  if (!options.length && !label) {
    return null;
  }

  if (options.length === 1) {
    return (
      <div
        className={classes.singleTimezoneRoot}
        data-hook={TimezoneDataHooks.Main}
      >
        <Text
          className={classes.singleTimezone}
          data-hook={TimezoneDataHooks.singleTimezone}
        >
          {label}
        </Text>
      </div>
    );
  }

  return (
    <div
      className={classes.multiTimezoneRoot}
      data-hook={TimezoneDataHooks.Main}
    >
      <FloatingDropdown
        data-hook={TimezoneDataHooks.multiTimezone}
        label={label}
        onChange={(props) => {
          if (props?.id) {
            onTimeZoneChange(props.id);
          }
        }}
        options={options.map((option) => ({
          id: option.id,
          value: option.value,
          isSelectable: option.selected,
        }))}
      />
    </div>
  );
};
