import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { classes } from './Title.st.css';
import { TitleViewModel } from '../../../components/DailyAgenda/viewModel/titleViewModel/titleViewModel';
import { TitleDataHooks } from './const';

export interface TitleProps {
  titleViewModel?: TitleViewModel;
}

export const Title: React.FC<TitleProps> = ({ titleViewModel }) => {
  const { titleTagName, title } = titleViewModel || {};

  return (
    <div className={classes.root} data-hook={TitleDataHooks.Main}>
      <Text
        className={classes.headerTitle}
        tagName={titleTagName}
        data-hook={TitleDataHooks.HeaderTitle}
      >
        {title}
      </Text>
    </div>
  );
};
